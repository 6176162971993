import React from 'react';
import LabGallery from '@tra-sg/gatsby-theme-c360-portal/src/components/LabGallery';

const LabsPage = (props) => (
  <LabGallery
    metaTitle="Labs | c360"
    title="Labs"
    dashboardName="lab-page"
    pageComponent={props}
  />
);

export default LabsPage;
